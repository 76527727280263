/* Contact.css */
.contact-page {
	padding: 4rem 0;
	background-color: #f8f9fa;
  }
  
  .contact-header {
	text-align: center;
	margin-bottom: 3rem;
  }
  
  .contact-header h1 {
	font-size: 2.5rem;
	color: #333;
	margin-bottom: 1rem;
  }
  
  .contact-header p {
	color: #666;
	font-size: 1.1rem;
  }
  
  .contact-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 20px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 3rem;
  }
  
  .contact-info-section {
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	padding: 2rem;
  }
  
  .contact-info h2 {
	color: #333;
	margin-bottom: 2rem;
  }
  
  .info-item {
	display: flex;
	align-items: flex-start;
	margin-bottom: 2rem;
  }
  
  .info-item i {
	font-size: 1.5rem;
	color: #ff4d4d;
	margin-right: 1rem;
	margin-top: 0.25rem;
  }
  
  .info-item h3 {
	color: #333;
	margin-bottom: 0.5rem;
	font-size: 1.1rem;
  }
  
  .info-item p {
	color: #666;
	line-height: 1.6;
  }
  
  .map-container {
	margin-top: 2rem;
	border-radius: 10px;
	overflow: hidden;
  }
  
  .contact-form-section {
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	padding: 2rem;
  }
  
  .contact-form-section h2 {
	color: #333;
	margin-bottom: 2rem;
  }
  
  .contact-form {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
  }
  
  .form-group {
	display: flex;
	flex-direction: column;
  }
  
  .form-row {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
  }
  
  label {
	color: #333;
	margin-bottom: 0.5rem;
	font-weight: 500;
  }
  
  input,
  select,
  textarea {
	padding: 0.8rem;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 1rem;
	transition: border-color 0.3s ease;
  }
  
  input:focus,
  select:focus,
  textarea:focus {
	outline: none;
	border-color: #ff4d4d;
  }
  
  .submit-button {
	background-color: #ff4d4d;
	color: white;
	padding: 1rem;
	border: none;
	border-radius: 5px;
	font-size: 1.1rem;
	cursor: pointer;
	transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
	background-color: #ff3333;
  }
  
  .alert {
	padding: 1rem;
	border-radius: 5px;
	margin-bottom: 1rem;
  }
  
  .alert.success {
	background-color: #d4edda;
	color: #155724;
	border: 1px solid #c3e6cb;
  }
  
  .alert.error {
	background-color: #f8d7da;
	color: #721c24;
	border: 1px solid #f5c6cb;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
	.contact-container {
	  grid-template-columns: 1fr;
	}
  
	.form-row {
	  grid-template-columns: 1fr;
	}
  
	.contact-page {
	  padding: 2rem 0;
	}
  }
  
  /* Animation */
  @keyframes fadeIn {
	from {
	  opacity: 0;
	  transform: translateY(20px);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
  }
  
  .contact-info-section,
  .contact-form-section {
	animation: fadeIn 0.6s ease-out;
  }
  