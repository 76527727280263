.services {
	width: 100%;
}

/* Common section styles */
.section-content {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 20px;
}

.gallery-container {
	display: grid;
	gap: 1rem;
	padding: 1rem;
}

.gallery-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 8px;
	transition: transform 0.3s ease;
}

.gallery-image:hover {
	transform: scale(1.05);
}