/* Home.css */

/* General Styles */
.home {
	max-width: 100%;
	overflow-x: hidden;
  }
  
  .section-content {
	max-width: 1200px;
	margin: 0 auto;
	padding: 4rem 2rem;
  }
  
  /* Hero Section */
  .hero-section {
	background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
				url('../images/banners/BAT-Banner.png');
	background-size: cover;
	background-position: center;
	min-height: 80vh;
	display: flex;
	align-items: center;
	color: white;
  }
  
  .hero-content {
	text-align: center;
	padding: 2rem;
  }
  
  .hero-content h1 {
	font-size: 3.5rem;
	margin-bottom: 1rem;
  }
  
  .hero-subtitle {
	font-size: 1.5rem;
	margin-bottom: 2rem;
  }
  
  .hero-buttons {
	display: flex;
	gap: 1rem;
	justify-content: center;
  }
  
  .cta-button {
	padding: 1rem 2rem;
	border-radius: 30px;
	font-size: 1.1rem;
	font-weight: bold;
	cursor: pointer;
	transition: transform 0.3s ease;
  }
  
  .cta-button:hover {
	transform: translateY(-3px);
  }
  
  .cta-button.primary {
	background: #ff4d4d;
	color: white;
	border: none;
  }
  
  .cta-button.secondary {
	background: transparent;
	color: white;
	border: 2px solid white;
  }
  
  /* About Section */
  .about-section {
	background: #f8f9fa;
  }
  
  .stats-container {
	display: flex;
	justify-content: space-around;
	margin-top: 3rem;
	flex-wrap: wrap;
	gap: 2rem;
  }
  
  .stat-item {
	text-align: center;
  }
  
  .stat-number {
	display: block;
	font-size: 2.5rem;
	font-weight: bold;
	color: #ff4d4d;
  }
  
  .stat-label {
	font-size: 1.1rem;
	color: #666;
  }
  
  /* Services Section */
  .services-section {
	background: white;
  }
  
  .services-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 2rem;
	margin-top: 2rem;
  }
  
  .service-card {
	padding: 2rem;
	text-align: center;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease;
  }
  
  .service-card:hover {
	transform: translateY(-10px);
  }
  
  .service-icon {
	font-size: 3rem;
	margin-bottom: 1rem;
  }
  
  .service-card h3 {
	margin-bottom: 1rem;
	color: #333;
  }
  
  /* Equipment Section */
  .equipment-section {
	background: #f8f9fa;
  }
  
  .equipment-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 2rem;
	margin-top: 2rem;
  }
  
  .equipment-item {
	padding: 2rem;
	text-align: center;
	background: white;
	border-radius: 10px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .equipment-item h3 {
	color: #333;
	margin-bottom: 1rem;
  }
  
  /* Contact Section */
  .contact-section {
	background: linear-gradient(45deg, #ff4d4d, #ff8080);
	color: white;
	text-align: center;
  }
  
  .contact-section h2 {
	margin-bottom: 1rem;
  }
  
  .contact-section p {
	margin-bottom: 2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
	.hero-content h1 {
	  font-size: 2.5rem;
	}
  
	.hero-subtitle {
	  font-size: 1.2rem;
	}
  
	.hero-buttons {
	  flex-direction: column;
	  align-items: center;
	}
  
	.stats-container {
	  flex-direction: column;
	  align-items: center;
	}
  
	.section-content {
	  padding: 2rem 1rem;
	}
  }
  
  /* General Section Headings */
  section h2 {
	text-align: center;
	font-size: 2.5rem;
	margin-bottom: 2rem;
	color: #333;
  }
  
  /* Animations */
  @keyframes fadeIn {
	from {
	  opacity: 0;
	  transform: translateY(20px);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
  }
  
  .section-content {
	animation: fadeIn 1s ease-out;
  }
  