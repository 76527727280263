/* Footer.css */
.footer {
	background-color: #1a1a1a;
	color: #ffffff;
	padding: 4rem 0 0 0;
	font-size: 0.95rem;
  }
  
  .footer-content {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 20px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 2rem;
  }
  
  .footer-section {
	margin-bottom: 2rem;
  }
  
  .footer-section h3 {
	color: #ffffff;
	font-size: 1.5rem;
	margin-bottom: 1rem;
  }
  
  .footer-section h4 {
	color: #ffffff;
	font-size: 1.2rem;
	margin-bottom: 1rem;
  }
  
  .footer-section p {
	color: #b3b3b3;
	line-height: 1.6;
  }
  
  .footer-section ul {
	list-style: none;
	padding: 0;
  }
  
  .footer-section ul li {
	margin-bottom: 0.8rem;
  }
  
  .footer-section ul li a {
	color: #b3b3b3;
	text-decoration: none;
	transition: color 0.3s ease;
  }
  
  .footer-section ul li a:hover {
	color: #ff4d4d;
  }
  
  .contact-info li {
	display: flex;
	align-items: center;
	gap: 10px;
	color: #b3b3b3;
  }
  
  .contact-info i {
	color: #ff4d4d;
	width: 20px;
  }
  
  .social-links {
	display: flex;
	gap: 1rem;
	margin-top: 1rem;
  }
  
  .social-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background-color: #333;
	border-radius: 50%;
	color: white;
	text-decoration: none;
	transition: all 0.3s ease;
  }
  
  .social-icon:hover {
	background-color: #ff4d4d;
	transform: translateY(-3px);
  }
  
  .footer-bottom {
	margin-top: 3rem;
	padding: 1.5rem 20px;
	background-color: #111111;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
  }
  
  .copyright {
	color: #b3b3b3;
  }
  
  .footer-links {
	display: flex;
	gap: 1.5rem;
  }
  
  .footer-links a {
	color: #b3b3b3;
	text-decoration: none;
	transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
	color: #ff4d4d;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
	.footer-content {
	  grid-template-columns: 1fr;
	}
  
	.footer-bottom {
	  flex-direction: column;
	  text-align: center;
	  padding: 1rem 20px;
	}
  
	.footer-links {
	  justify-content: center;
	}
  }
  
  /* Animation for social icons */
  @keyframes pulse {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.1);
	}
	100% {
	  transform: scale(1);
	}
  }
  
  .social-icon:hover i {
	animation: pulse 0.8s infinite;
  }
  