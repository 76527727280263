.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 2rem;
	background-image: linear-gradient(to right, #ff0844 0%, #ffb199 100%);
	color: white;
  }
  
  .nav-left {
	display: flex;
	align-items: center;
	gap: 1rem;
  }
  
  .nav-logo {
	height: 40px;
	width: auto;
  }
  
  .nav-brand {
	color: white;
	text-decoration: none;
	font-size: 1.5rem;
	font-weight: bold;
  }
  
  .nav-right {
	display: flex;
	align-items: center;
	gap: 2rem;
  }
  
  .nav-links {
	display: flex;
	gap: 1.5rem;
  }
  
  .nav-links a {
	color: white;
	text-decoration: none;
  }
  
  .nav-links a:hover {
	text-decoration: underline;
  }
  
  .dropdown {
	position: relative;
  }
  
  .dropdown-button {
	background: transparent;
	border: 2px solid white;
	color: white;
	padding: 0.5rem 1rem;
	cursor: pointer;
	border-radius: 4px;
  }
  
  .dropdown-content {
	position: absolute;
	right: 0;
	top: 100%;
	background: white;
	min-width: 160px;
	box-shadow: 0 2px 5px rgba(0,0,0,0.2);
	border-radius: 4px;
	padding: 0.5rem 0;
  }
  
  .dropdown-content a {
	color: #333;
	padding: 0.5rem 1rem;
	text-decoration: none;
	display: block;
  }
  
  .dropdown-content a:hover {
	background-color: #f5f5f5;
  }
  
  @media (max-width: 768px) {
	.nav-links {
	  display: none;
	}
	
	.navbar {
	  padding: 1rem;
	}
  }
  