/* App.css */
.App {
	text-align: center;
  }
  
  .hero {
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
				url('./images/banners/Luxury_Background.jpg');
	background-size: cover;
	background-position: center;
	color: white;
  }
  
  .hero h1 {
	font-size: 3rem;
	margin-bottom: 1rem;
  }
  
  .cta-button {
	padding: 1rem 2rem;
	font-size: 1.2rem;
	background-color: #ff4d4d;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
  }
  
  .features {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 2rem;
	padding: 4rem 2rem;
  }
  
  .feature {
	padding: 2rem;
	background-color: #f5f5f5;
	border-radius: 10px;
	transition: transform 0.3s;
  }
  
  .feature:hover {
	transform: translateY(-5px);
  }
  