/* Gallery.css */
.gallery-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 2rem;
  }
  
  .slideshow-container {
	position: relative;
	max-width: 1000px;
	margin: 0 auto;
	height: 600px;
	overflow: hidden;
  }
  
  .slide {
	width: 100%;
	height: 100%;
	position: relative;
  }
  
  .slide img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 8px;
  }
  
  .slide-content {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px;
	background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
	color: white;
  }
  
  .prev, .next {
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	padding: 16px;
	color: white;
	font-size: 24px;
	background: rgba(0, 0, 0, 0.6);
	border: none;
	border-radius: 50%;
	transition: 0.3s ease;
  }
  
  .prev:hover, .next:hover {
	background: rgba(0, 0, 0, 0.8);
  }
  
  .prev {
	left: 10px;
  }
  
  .next {
	right: 10px;
  }
  
  .dots-container {
	position: absolute;
	bottom: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 8px;
  }
  
  .dot {
	cursor: pointer;
	height: 12px;
	width: 12px;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 50%;
	transition: 0.3s ease;
  }
  
  .dot.active, .dot:hover {
	background-color: white;
  }
  
  .thumbnails {
	display: flex;
	gap: 10px;
	margin-top: 20px;
	overflow-x: auto;
	padding: 10px 0;
  }
  
  .thumbnail {
	cursor: pointer;
	width: 100px;
	height: 60px;
	flex-shrink: 0;
	opacity: 0.6;
	transition: 0.3s ease;
  }
  
  .thumbnail.active {
	opacity: 1;
	border: 2px solid #ff4d4d;
  }
  
  .thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;
  }
  
  /* Animation */
  .slide {
	animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
	.slideshow-container {
	  height: 400px;
	}
  
	.thumbnail {
	  width: 80px;
	  height: 50px;
	}
  
	.slide-content h3 {
	  font-size: 1.2rem;
	}
  
	.slide-content p {
	  font-size: 0.9rem;
	}
  }
  